@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.body, html {
  @apply font-poppins;
}

:disabled {
  opacity: 0.3;
}

#root {
  @apply h-full;
}

.w-content {
  @apply mx-auto w-full p-5 h-dvh flex flex-col gap-3 overflow-hidden;
}

.w-content .content-img {
  height: 70%;
  object-fit: contain;
}

.gender {
  background-position-y: bottom;
  background-position-x: center;
  background-size: contain;
  @apply
  relative
    /*bg-[url('./ellipse-sm.png')]*/
  bg-no-repeat;
  height: 40%;
  flex-grow: 1;
}

.gender img {
  /*height: 90%;*/
}



select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./arrow-down.svg');
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}

.custom-facebook-icon {
  background-color: #3b5998;
  color: red
}

.navbar-nav .nav-item .nav-link:hover {
  color: black;
  background-color: #f0f0f0;
}

/* Loader 6 */
.loader {
  height: 32px;
  width: 32px;
}

.loader span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
}

.loader span::before,
.loader span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  border: 2px solid #3b5998;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
  animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
}

@-webkit-keyframes loader-6-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}

@keyframes loader-6-1 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}

.loader span::after {
  -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
  animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
}

@-webkit-keyframes loader-6-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
}

@keyframes loader-6-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
}

/* Layout */
.center {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.privacy-policy p{
  margin-top: 15px;
  font-size: 12px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}